import React, {FormEvent, FormEventHandler} from 'react';
import sheers from './sheers200.png';
import './App.css';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, styled, TextField } from '@material-ui/core';

const SubscribeButton = styled(Button)({
  background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  border: 0,
  borderRadius: 3,
  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  color: 'white',
  height: 48,
  padding: '0 30px',
});

function App() {
  const [email, setEmail] = React.useState('');
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    console.log(`form`)
    setOpen(false);
  };

  return (
    <div className="App">
      <header className="App-header">
        <img src={sheers} className="App-logo" alt="logo" />
        <p>
          Salon Josie
        </p>
        <p>
            <SubscribeButton onClick={handleClickOpen}>
              SUBSCRIBE TO OUR MAILING LIST
            </SubscribeButton>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <form action="https://salonjosie.us1.list-manage.com/subscribe/post" method="POST">
        <DialogTitle id="form-dialog-title">Subscribe to our mailing list</DialogTitle>
        <DialogContent>
        <input type="hidden" name="u" value="29ecc33102c0a04442917ce68" />
          <input type="hidden" name="id" value="1a60f74d6e" />
          <TextField
            autoFocus
            required
            margin="dense"
            id="email"
            label="Email Address"
            type="email"
            name="EMAIL"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            fullWidth
          />
          <TextField
            autoFocus
            margin="dense"
            id="fname"
            label="First Name"
            type="text"
            name="FNAME"
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
            fullWidth
          />
          <TextField
            autoFocus
            margin="dense"
            id="lname"
            label="Last Name"
            type="text"
            name="LNAME"
            onChange={(e) => {
              setLastName(e.target.value);
            }}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button color="primary" type="submit">
            Subscribe
          </Button>
        </DialogActions>
        </form>
      </Dialog>
          </p>
      </header>
      <footer>&copy; 2021 Salon Josie &bull; 180 Lincoln Pl, Brooklyn, NY 11217 &bull;&nbsp; 
        <a href="mailto:hello@salonjosie.com">Email Us</a></footer>
    </div>
  );
}

export default App;
